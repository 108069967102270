<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#FF8000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M5.83337 8.33325L10 12.4999L14.1667 8.33325" stroke="#FF8000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    <path d="M10 12.5V2.5" stroke="#FF8000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
  </svg>
</template>

<script>
export default {
  name: 'DownloadIco'
}
</script>
