<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <ApolloQuery :query="require('@/graphql/queries/Contract.gql')" :variables="{ id }">
    <template slot-scope="{ result: { loading, error, data } }">
      <h1 v-if="loading" class="loading apollo">Загрузка</h1>
      <h1 v-else-if="error" class="error apollo">Ошибка, обратитесь в службу поддержки</h1>
      <main class="contract__page" role="main" v-if="!loading && data && data.Contract">
        <div class="modal" style="align-items: center;" v-if="actModal">
          <div class="modal__back" @click="actModal = false"></div>
          <div class="modal__body" style="max-width: 50%;">
            <div class="modal__attention" style="white-space: pre-wrap;">
              <span class="modal__attention__title">Вы действительно хотите создать акт передачи?</span>
              <div class="modal__attention__btns" style="justify-content:center;">
                <button class="modal__attention__btn modal__attention__btn--orange" @click="createAct(data.Contract.id, data.Contract.contract_id)">Создать</button>
                <button class="modal__attention__btn" style="margin: 0px 5px;" @click="actModal = false">Закрыть</button>
              </div>
            </div>
          </div>
        </div>
        <ContractFilesModal
          :contractId = "data.Contract.contract_id"
          :contractName = "data.Contract.name"
          :filesModal="filesModal"
          :isDownload="isDownload"
          :apolloData = "apolloData"
          :signers = "data.Contract.signers[0]"
          :mongoId = "data.Contract.id"
          v-on:switchModal="filesModal = !filesModal; isDownload = false"
        />
        <ContractBuyoutModal
          :contractId = "data.Contract.contract_id"
          :contractName = "data.Contract.name"
          :buyoutModal="buyoutModal"
          v-on:switchModal="buyoutModal = !buyoutModal"
        />
        <div class="main__header">
          <div class="main__header__item left"></div>
          <div class="main__header__item">
            <h1>Договор {{ data.Contract.name }} от {{ getDate(data.Contract.date) }}</h1>
          </div>
          <div class="main__header__item right"></div>
        </div>
        <div class="main__content">
          <tabs class="lsg-tabs contract-tabs" :options="{ useUrlFragment: false }">
            <tab name="Основная информация">
              <div class="contract">
                <div class="contract__sections">
                  <div class="contract__section">
                    <h3 class="contract__section__title">Общие сведения</h3>
                    <div class="contract__section__body">
                      <i class="contract__section__bg">
                        <ContractSectionBackIcon/>
                      </i>
                      <div class="contract__section__item" v-if="$store.state.me.role !== 'partner' && data.Contract.partner">
                        <span class="contract__section__item__title">Контрагент</span>
                        <div class="contract__section__item__content">
                          <router-link :to="{ name: 'company', params: { id: data.Contract.partner.id }}">{{ data.Contract.partner.name }}</router-link>
                        </div>
                      </div>
                      <div class="contract__section__item" v-if="$store.state.me.role !== 'partner' && data.Contract.lead">
                        <span class="contract__section__item__title">Проект</span>
                        <div class="contract__section__item__content">
                          <router-link :to="{ name: 'trade', params: { id: data.Contract.lead.id }}">{{ data.Contract.lead.name || data.Contract.partner.name }}</router-link>
                        </div>
                      </div>
                      <div class="contract__section__item">
                        <span class="contract__section__item__title">Сумма договора</span>
                        <div class="contract__section__item__content">
                          <span>{{ prettifyPrice(data.Contract.summ) }}</span>
                        </div>
                      </div>
                      <div class="contract__section__item">
                        <span class="contract__section__item__title">Статус</span>
                        <div class="contract__section__item__content">
                          <span class="tag" :class="getStatusColor(data.Contract.status)">{{ data.Contract.status }}</span>
                        </div>
                      </div>
                      <div class="contract__section__item">
                        <span class="contract__section__item__title">Менеджер</span>
                        <div class="contract__section__item__content">
                          <span>{{ data.Contract.manager && data.Contract.manager.name ? data.Contract.manager.name : 'Отсутствует' }}</span>
                        </div>
                      </div>
                      <div class="contract__section__item">
                        <span class="contract__section__item__title">Телефон</span>
                        <div class="contract__section__item__content">
                          <span v-if="data.Contract.manager && data.Contract.manager.phone">{{ data.Contract.manager.phone }}</span>
                          <span v-else>88005057493</span>
                        </div>
                      </div>
                      <div class="contract__section__item">
                        <span class="contract__section__item__title">Срок лизинга (мес)</span>
                        <div class="contract__section__item__content">
                          <span>{{ data.Contract.duration }}</span>
                        </div>
                      </div>
                      <div class="contract__section__item">
                        <span class="contract__section__item__title">Балансодержатель</span>
                        <div class="contract__section__item__content">
                          <span>{{ data.Contract.balanceholder }}</span>
                        </div>
                      </div>
                      <div class="contract__section__item">
                        <span class="contract__section__item__title">Текущая задолженность</span>
                        <div class="contract__section__item__content">
                          <span>{{ prettifyPrice(data.Contract.debt) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="contract__section">
                    <h3 class="contract__section__title">Документы</h3>
                    <div class="contract__section__body">
                      <vue-good-table :columns="clientFilesColumns" :pagination-options="{enabled: false}" :rows="data && data.Contract ? data.Contract.files : []" compactMode>
                        <template slot="table-row" slot-scope="props">
                          <div v-if="props.column.field === 'status' && props.row.status" v-tooltip="props.row.status" :class="statusFilter(props.row.status)"><EdoIcon/></div>
                          <span v-if="props.column.field === 'type'">{{ props.row.type }}</span>
                          <span v-if="props.column.field === 'ext'">{{ props.row.ext }}</span>
                          <a v-if="props.column.field === 'action'" :href="'https://' + props.row.path" download v-tooltip="'Скачать документ'">
                            <DownloadIco/>
                          </a>
                        </template>
                        <div slot="emptystate">Данных нет</div>
                      </vue-good-table>
                    </div>
                  </div>
                </div>
                <div class="contract__lsg">
                  <h3 class="contract__section__title">Предметы лизинга</h3>
                  <ul class="contract__lsg__list">
                    <li>
                      <div class="contract__lsg__item">
                        <span>Наименование</span>
                        <span>{{ data.Contract.property_name }}</span>
                      </div>
                      <div class="contract__lsg__item">
                        <span>VIN</span>
                        <span>{{ data.Contract.property_vin }}</span>
                      </div>
                      <div class="contract__lsg__item">
                        <span>Гос. номер</span>
                        <span>{{ data.Contract.property_sign }}</span>
                      </div>
                      <template v-if="data.Contract.beacons.length && !['partner', 'agent'].includes($store.state.me.role)">
                        <div v-for="beacon in data.Contract.beacons" :key="beacon.id">
                          <div class="contract__lsg__item">
                            <span>Предмет</span>
                            <span>{{ beacon.subject || '-' }}</span>
                          </div>
                          <div class="contract__lsg__item">
                            <span>Цвет</span>
                            <span>{{ beacon.color || '-' }}</span>
                          </div>
                          <div class="contract__lsg__item">
                            <span>Выдача</span>
                            <span>{{ new Date(beacon.date_of_issue).toLocaleString('ru-RU') === '01.01.1, 00:00:00' ? '-' : new Date(beacon.date_of_issue).toLocaleString('ru-RU') }}</span>
                          </div>
                          <div class="contract__lsg__item">
                            <span>Конт. номер менеджера</span>
                            <span>{{ beacon.manager_phone || '-' }}</span>
                          </div>
                          <div class="contract__lsg__item">
                            <span>Автосалон</span>
                            <span>{{ beacon.provider_name || '-' }}</span>
                          </div>
                          <div class="contract__lsg__item">
                            <span>Адрес</span>
                            <span>{{ beacon.provider_address || '-' }}</span>
                          </div>
                          <div class="contract__lsg__item">
                            <span>Представитель</span>
                            <span>{{ beacon.provider_contact || '-' }}</span>
                          </div>
                          <div class="contract__lsg__item">
                            <span>Комплект</span>
                            <span>{{ beacon.kit || '-' }}</span>
                          </div>
                          <div class="contract__lsg__item">
                            <span>Установка (план)</span>
                            <span>{{ new Date(beacon.installation_date_plan).toLocaleString('ru-RU') === '01.01.1, 00:00:00' ? '-' : new Date(beacon.installation_date_plan).toLocaleString('ru-RU') }}</span>
                          </div>
                          <div v-if="beacon.installation_date_fact" class="contract__lsg__item">
                            <span>Установка (факт)</span>
                            <span>{{ new Date(beacon.installation_date_fact).toLocaleString('ru-RU') === '01.01.1, 00:00:00' ? '-' : new Date(beacon.installation_date_fact).toLocaleString('ru-RU') }}</span>
                          </div>
                        </div>
                      </template>
                    </li>
                    <input type="button" v-if="!data.Contract.beacons.length && !showBeacon && !['partner', 'agent'].includes($store.state.me.role)" class="btn" value="Создание маяка" @click="showBeacon = true">
                    <li v-if="showBeacon && !['partner', 'agent'].includes($store.state.me.role)">
                      <div class="contract__lsg__item" style="margin-bottom: 20px; margin-top: 10px;">
                        <span>Выдача</span>
                        <input type="datetime-local" v-model="beaconData.dateOfIssue" style="max-width: 200px">
                      </div>
                      <div class="contract__lsg__item" style="margin-bottom: 20px">
                        <span>Телефон</span>
                        <input
                          type="tel"
                          v-model="beaconData.managerPhone"
                          name="phone_number"
                          style="max-width: 200px"
                          list="tel-list"
                          :placeholder="data.Contract.manager.phone || 79876543210"
                        >
                        <datalist id="tel-list">
                          <option
                            v-if="data.Contract.manager && data.Contract.manager.phone"
                            :value="data.Contract.manager.phone"
                            :label="data.Contract.manager.name"
                          />
                        </datalist>
                      </div>
                      <div class="contract__lsg__item" style="margin-bottom: 20px">
                        <span>Адрес автосалона</span>
                        <input
                          type="text"
                          v-model="beaconData.providerAddress"
                          style="max-width: 200px"
                          placeholder="420000, Татарстан респ, Казань г, ..."
                        >
                      </div>
                      <div class="contract__lsg__item" style="margin-bottom: 20px">
                        <span>Представитель</span>
                        <input
                          type="text"
                          v-model="beaconData.providerContact"
                          style="max-width: 200px"
                          placeholder="Иван, 79876543210"
                        >
                      </div>
                      <div class="contract__lsg__item" style="margin-bottom: 20px">
                        <span>Комплект</span>
                        <select v-model="beaconData.kit">
                          <option value="Маяк" selected>Маяк</option>
                          <option value="Онлайн маяк">Онлайн маяк</option>
                        </select>
                      </div>
                      <div class="contract__lsg__item" style="margin-bottom: 20px">
                        <span>Установка (план)</span>
                        <input type="datetime-local" v-model="beaconData.installationDate" style="max-width: 200px">
                      </div>
                      <div class="beacon-buttons">
                        <div v-if="!sendingData" class="btn btn--orange" @click="createBeacon(data.Contract.contract_id)">Создать</div>
                        <button v-else class="btn" disabled>Создать</button>
                        <div class="btn btn--gray" @click="showBeacon = false">Скрыть</div>
                      </div>
                    </li>
                  </ul>
                  <h3 class="contract__section__title">Сформировать документы</h3>
                  <ul class="contract__section__body">
                    <vue-good-table :columns="formColumns" :pagination-options="{enabled: false}" :rows="apolloData && apolloData.maketList ? ( data.Contract.status === 'Действует' ? dataProcessing(apolloData.maketList) : dataExistProcessing(apolloData.maketList)) : []" compactMode>
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field === 'name'">{{ props.row.name }}</span>
                          <span v-if="props.column.field === 'ext'">pdf</span>
                          <a v-if="props.column.field === 'action'" :href="`${baseURL}/download?type=contract&name=${data.Contract.name.split('-')[0]}&maket=${props.row.id}&id=${data.Contract.contract_id}_blank`" download v-tooltip="'Скачать документ'">
                            <DownloadIco/>
                          </a>
                        </template>
                        <div slot="emptystate">Данных нет</div>
                      </vue-good-table>
                  </ul>
                </div>
              </div>
            </tab>
            <tab name="График платежей" :is-disabled="!(data.Contract && data.Contract.payments.length)">
              <div class="_row">
                <div class="calc__info__download _col-1">
                  <button class="btn btn--primary" @click="buyoutModal = true">
                    <i>
                      <ActIcon/>
                    </i>
                    <span>Расчет досрочного погашения</span>
                  </button>
                  <!-- <checkbox>Показать столбец досрочного погашения</checkbox> -->
                </div>
              </div>
              <div class="_row" v-if="data && data.Contract">
                <div class="_col-2 contract__graphic-table">
                  <vue-good-table :columns="paymentColumns" :pagination-options="{enabled: false}" :rows="data.Contract.payments.map((pay, i) => {pay.number = i + 1; return pay}).splice(0, parseInt(data.Contract.payments.length / 2) + 1)" compactMode>
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field === 'number'">{{ props.row.number }}</span>
                      <span v-if="props.column.field === 'date'">{{ getDate(props.row.date) }}</span>
                      <span v-if="props.column.field === 'plan'">{{ prettifyPrice(props.row.plan) }}</span>
                      <span v-if="props.column.field === 'paid'">{{ prettifyPrice(props.row.paid) }}</span>
                    </template>
                    <div slot="emptystate">Данных нет</div>
                  </vue-good-table>
                </div>
                <div class="_col-2  contract__graphic-table">
                  <vue-good-table :columns="paymentColumns" :pagination-options="{enabled: false}" :rows="data.Contract.payments.map((pay, i) => {pay.number = i + 1; return pay}).splice(parseInt(data.Contract.payments.length / 2) + 1)" compactMode>
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field === 'number'">{{ props.row.number }}</span>
                      <span v-if="props.column.field === 'date'">{{ getDate(props.row.date) }}</span>
                      <span v-if="props.column.field === 'plan'">{{ prettifyPrice(props.row.plan) }}</span>
                      <span v-if="props.column.field === 'paid'">{{ prettifyPrice(props.row.paid) }}</span>
                    </template>
                    <div slot="emptystate">Данных нет</div>
                  </vue-good-table>
                </div>
              </div>
              <!-- <div class="_row">
                <div class="calc__info__download _col-1">
                  <button class="btn">
                    <i>
                      <PrintIcon/>
                    </i>
                    <span>РАСПЕЧАТАТЬ</span>
                  </button>
                </div>
              </div> -->
            </tab>
            <tab name="Первичные документы" :is-disabled="!(data.Contract && data.Contract.archives.length)">
              <div class="contract__doc-filter">
                <span class="contract__doc-filter__title">Акт сверки</span>
                <div class="contract__doc-filter__container">
                  <!-- <div class="contract__doc-filter__item">
                    <v-select appendToBody :options="periods" placeholder="Период" @input="onPeriodSelect">
                      <template slot="no-options">Нет результатов</template>
                      <template #open-indicator="{ attributes }">
                        <div v-bind="attributes">
                          <DownIcon/>
                        </div>
                      </template>
                    </v-select>
                  </div> -->
                  <div class="contract__doc-filter__item">
                    <date-picker :formatter="momentFormat" range v-model="period"/>
                  </div>
                  <div class="contract__doc-filter__item">
                    <button class="btn btn--gray" @click="generateAct(data.Contract.partner.id, data.Contract.id)">
                      <i>
                        <ActIcon/>
                      </i>
                      <span>Сформировать</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="tbl">
                <vue-good-table :columns="archiveColumns" :pagination-options="{enabled: false}" :rows="data && data.Contract ? data.Contract.archives : []" compactMode>
                  <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'status' && props.row.status" v-tooltip="props.row.status" :class="statusFilter(props.row.status)"><EdoIcon/></div>
                    <span v-if="props.column.field === 'name'">{{ props.row.name }}</span>
                    <span v-if="props.column.field === 'type'">{{ props.row.type }}</span>
                    <span v-if="props.column.field === 'summ'">{{ prettifyPrice(props.row.summ) }}</span>
                    <a v-if="props.column.field === 'action'" @click="downloadAct(props.row.id)" v-tooltip="downTitle">
                      <DownloadIco/>
                    </a>
                  </template>
                  <div slot="emptystate">Данных нет</div>
                </vue-good-table>
              </div>
            </tab>
            <tab v-if="$store.state.me.role !== 'partner'" name="Документы" :is-disabled="!(data.Contract)"> <!-- && data.Contract.files && data.Contract.files.length -->
              <div class="contract__doc-filter" v-if="$store.state.me.role !== 'partner' && apolloData">
                <div class="contract__doc-filter__container">
                  <div v-if="selectedSendEdoDocs.length" class="contract__doc-filter__item">
                    <button
                      class="btn btn--primary"
                      @click="showEdoModal(
                        data.Contract.partner,
                        null,
                        selectedSendEdoDocs.map(elem => {
                          return {
                            id: elem.id,
                            inn: data.Contract.partner.inn,
                            filename: checkFilename(elem.name, elem.ext),
                            contractId: data.Contract.id,
                            documentView: elem.view,
                            documentType: elem.type,
                            path: elem.path
                          }
                        }),
                        data.Contract.provider,
                        data.Contract.guarantor
                      )"
                    >
                      <span>Отправить на подпись</span>
                    </button>
                  </div>
                  <div class="contract__doc-filter__item">
                    <button class="btn btn--primary" @click="filesModal = true">
                      <i>
                        <ActIcon/>
                      </i>
                      <span>Сформировать документы</span>
                    </button>
                  </div>
                  <div class="contract__doc-filter__item">
                    <button class="btn btn--green" @click="filesModal = true; isDownload = true">
                      <i>
                        <ActIcon/>
                      </i>
                      <span>Загрузить документы</span>
                    </button>
                  </div>
                  <div class="contract__doc-filter__item" v-if="!data.Contract.delivered">
                    <button :disabled="['Закрыт', 'Действует'].includes(data.Contract.status)" class="btn btn--primary" @click="actModal = !actModal">
                      <span>Сформировать акт передачи</span>
                    </button>
                  </div>

                </div>
              </div>
              <div class="tbl">
                <vue-good-table
                  :columns="[{ label: '', field: 'sendToSign', width: '50px', sortable: false }, ...filesColumns]"
                  :pagination-options="{enabled: false}"
                  :rows="data && data.Contract ? data.Contract.files : []"
                  compactMode
                >
                  <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'status' && props.row.status" v-tooltip="props.row.status" :class="statusFilter(props.row.status)"><EdoIcon/></div>
                    <div v-else-if="props.column.field === 'status' && !props.row.status" style="min-height: 20px;"></div>
                    <span v-if="props.column.field === 'name'">{{ props.row.name }}</span>
                    <span v-if="props.column.field === 'type'">{{ props.row.type }}</span>
                    <a v-if="props.column.field === 'action'" :href="'https://' + props.row.path" download v-tooltip="'Скачать документ'">
                      <DownloadIco/>
                    </a>
                    <div v-if="props.column.field === 'sendToSign' && props.row.canSign && !props.row.status">
                      <input type="checkbox" v-model="selectedSendEdoDocs" :id="props.row.id" :value="props.row">
                    </div>
                  </template>
                  <div slot="emptystate">Данных нет</div>
                </vue-good-table>
              </div>
            </tab>
          </tabs>
        </div>
      </main>
    </template>
  </ApolloQuery>
</template>

<script>
import GENERATE_ACT from '@/graphql/mutations/GenerateAct.gql'
import CREATE_ACT from '@/graphql/mutations/CreateAct.gql'
import CREATE_BEACON from '@/graphql/mutations/CreateBeacon.gql'
import moment from 'moment'
import ContractSectionBackIcon from '@/components/svg/ContractSectionsBack'
import ContractFilesModal from '../../components/layouts/modals/components/ContractFilesModal'
import ContractBuyoutModal from '../../components/layouts/modals/components/ContractBuyoutModal.vue'
import DownloadIco from '@/components/svg/download'
import ActIcon from '../../components/svg/ActIcon'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import EdoIcon from '@/components/svg/EdoIcon'

import gql from 'graphql-tag'
import GraphQLHelper from '@/helpers/GraphQLHelper'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Contract',
  components: { ActIcon, ContractSectionBackIcon, DownloadIco, DatePicker, ContractFilesModal, ContractBuyoutModal, EdoIcon }, // DiadocIcon
  props: ['id'],
  beforeUpdate () {
    if (!this.filesModal) this.isDownload = false
  },
  data () {
    return {
      baseURL: process.env.VUE_APP_HTTP,
      showBeacon: false,
      filesModal: false,
      isDownload: false,
      sendingData: false,
      buyoutModal: this.$store.state.buyoutModalToggle || false,
      actModal: false,
      selectedSendEdoDocs: [],
      contractTypes: ['Имущество', 'КАСКО', 'ОСАГО', 'Оборудование', 'Спецтехника'],
      downTitle: 'Скачать счет-фактуру и акт (pdf)',
      beaconData: {
        dateOfIssue: null,
        managerPhone: null,
        providerAddress: null,
        providerContact: null,
        kit: 'Маяк',
        installationDate: null
      },
      paymentColumns: [
        {
          label: '№',
          field: 'number'
        },
        {
          label: 'Дата',
          field: 'date'
        },
        {
          label: 'Запланированное',
          field: 'plan'
        },
        {
          label: 'Оплачено',
          field: 'paid'
        }
      ],
      archiveColumns: [
        {
          label: 'ЭДО',
          field: 'status'
        },
        {
          label: 'Счет-фактура',
          field: 'name'
        },
        {
          label: 'Вид счет-фактуры',
          field: 'type'
        },
        {
          label: 'Сумма',
          field: 'summ'
        },
        {
          label: '',
          field: 'action',
          sortable: false
        }
      ],
      filesColumns: [
        {
          label: 'ЭДО',
          field: 'status',
          width: '50px',
          sortable: false
        },
        {
          label: 'Тип',
          field: 'type'
        },
        {
          label: 'Наименование',
          field: 'name'
        },
        // {
        //   label: 'Формат',
        //   field: 'ext'
        // },
        {
          label: '',
          field: 'action',
          sortable: false
        }
      ],
      clientFilesColumns: [
        {
          label: 'ЭДО',
          field: 'status'
        },
        {
          label: 'Тип',
          field: 'type'
        },
        {
          label: 'Формат',
          field: 'ext'
        },
        {
          label: '',
          field: 'action',
          sortable: false
        }
      ],
      formColumns: [
        {
          label: 'Документ',
          field: 'name'
        },
        {
          label: 'Формат',
          field: 'ext'
        },
        {
          label: '',
          field: 'action',
          sortable: false
        }
      ],
      lastDocsColumns: [
        {
          label: 'Название',
          field: 'name'
        },
        {
          label: '',
          field: 'download',
          sortable: false
        },
        {
          label: 'Тип документа',
          field: 'type'
        },
        {
          label: 'Дата',
          field: 'date'
        }
      ],
      periods: [{
        label: 'Последний квартал',
        value: '0'
      }, {
        label: 'Первое полугодие',
        value: '1'
      }, {
        label: 'Последний месяц',
        value: '2'
      }],
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'DD.MM.YYYY').toDate() : null
        }
      },
      period: null
    }
  },
  apollo: {
    apolloData () {
      return {
        query: gql`
          query {
            maketList
            documentTypes
          }
        `,
        update: (data) => {
          this.$nextTick(() => {
            this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
          })
          return data
        }
      }
    }
  },
  methods: {
    checkFilename (filename, ext) {
      if (filename.indexOf('.') === -1) return `${filename}.${ext}`
      return filename
    },
    showEdoModal (partner, edoData, edoDataArray, provider, guarantor) {
      this.$store.state.data.edo.modal.show = !this.$store.state.data.edo.modal.show
      this.$store.state.data.edo.modal.recipientList = [
        {
          name: partner.name,
          inn: partner.inn
        }
      ]

      if (provider.name && provider.inn) {
        this.$store.state.data.edo.modal.recipientList.push({
          name: provider.name,
          inn: provider.inn
        })
      }

      this.$store.state.data.edo.modal.recipient = {
        name: partner.name,
        inn: partner.inn
      }

      if (guarantor) {
        this.$store.state.data.edo.modal.guarantor = {
          name: guarantor.name,
          inn: guarantor.inn
        }
      }

      this.$store.state.data.edo.modal.edoData = edoData
      this.$store.state.data.edo.modal.edoDataArray = edoDataArray
    },
    statusFilter (status) {
      if (['Подписан контрагентом', 'Выполнение завершено успешно', 'Успешно'].includes(status)) return 'edo-icon--success'
      else if (['Отправлен', 'Доставлен'].includes(status)) return 'edo-icon--sent'
      else if (['Ошибка', 'В подписи отказано', 'Контрагент отказал в подписи'].includes(status)) return 'edo-icon--error'
      return 'edo-icon'
    },
    async createBeacon (contractId) {
      if (this.isValidBeaconData()) {
        this.sendingData = true
        const data = this.beaconData

        // После повторный отправки данных меняется структура даты (добавляются секунды)
        if (data.dateOfIssue.split('T')[1].split(':').length === 2) {
          data.dateOfIssue = data.dateOfIssue + ':00'
        }
        if (data.installationDate.split('T')[1].split(':').length === 2) {
          data.installationDate = data.installationDate + ':00'
        }

        await this.$apollo.mutate({
          mutation: CREATE_BEACON,
          variables: {
            ...this.beaconData, contract_id: contractId
          },
          update: (_, { data: { createBeacon } }) => {
            if (createBeacon === '') {
              this.$notify({
                group: 'lsg-notify',
                duration: 10000,
                text: 'Успешно. Изменения вступят в силу через какое-то время'
              })
            } else {
              this.$notify({
                group: 'lsg-notify',
                duration: 1000,
                text: 'Ошибка'
              })
            }
            this.sendingData = false
          }
        }).catch(error => {
          this.sendingData = false
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
        // Делаю по умолчанию
        this.beaconData = {
          dateOfIssue: null,
          managerPhone: null,
          providerAddress: null,
          providerContact: null,
          kit: 'Маяк',
          installationDate: null
        }
      }
    },
    isValidBeaconData () {
      const isValidDate = (date) => {
        if (isNaN(Date.parse(date))) {
          this.$notify({
            group: 'lsg-notify',
            text: 'Не валидная дата'
          })
          return false
        }
        const newDate = new Date(date)
        if (
          new Date(date).getTime() - new Date().getTime() <= (12 * 60 * 60 * 1000) ||
          (newDate.getHours() > 20 || newDate.getHours() < 5)
        ) {
          this.$notify({
            group: 'lsg-notify',
            text: 'Время должно быть указано между 5:00 и 21:00 и +12 часов от текущего времени'
          })
          return false
        }
        return true
      }
      if (
        this.beaconData.dateOfIssue && isValidDate(this.beaconData.dateOfIssue) &&
        this.beaconData.managerPhone && /^\d+$/.test(this.beaconData.managerPhone) &&
        this.beaconData.providerAddress &&
        this.beaconData.providerContact &&
        this.beaconData.kit &&
        this.beaconData.installationDate && isValidDate(this.beaconData.dateOfIssue)
      ) return true
      else {
        this.$notify({
          group: 'lsg-notify',
          text: 'Неверно заполнены поля'
        })
        return false
      }
    },
    dataProcessing (data) {
      const sort = ['Счет на оплату_Аванса', 'Счет на оплату_ЛизинговогоПлатежа'] // 'СЧЕТ-ОФЕРТА на оплату для ЧДП и ПДП'
      const filteredData = data.filter(elem => sort.includes(elem.name))
      return filteredData
    },
    dataExistProcessing (data) {
      const sort = ['Счет на оплату_Аванса']
      const filteredData = data.filter(elem => sort.includes(elem.name))
      return filteredData
    },
    onPeriodSelect (e) {
      return e
    },
    async generateAct (partnerId, contractId) {
      if (!partnerId) return
      this.$store.dispatch({ type: 'showOverlay', show: true, text: 'Подождите, идет обработка...' })

      await this.$apollo.mutate({
        mutation: GENERATE_ACT,
        variables: {
          partnerId: partnerId,
          contractId: contractId,
          period: {
            start: new Date(this.period[0].setDate(this.period[0].getDate() + 1)),
            end: new Date(this.period[1].setDate(this.period[1].getDate() + 1))
          }
        },
        update: (store, { data: { getActSverki } }) => {
          this.$store.dispatch({ type: 'hideOverlay' })
          if (getActSverki) {
            window.open(`${process.env.VUE_APP_HTTP}/download?type=as&name=LTas&id=${getActSverki}`, '_blank')
          } else {
            this.$notify({
              group: 'lsg-notify',
              duration: 10000,
              text: 'Ошибка формирования акта сверки. Обратитесь в тех. поддержку.'
            })
          }
        }
      })
    },
    async createAct (id, contractId) {
      await this.$apollo.mutate({
        mutation: CREATE_ACT,
        variables: {
          id: id,
          contractId: contractId
        },
        update: (_, { data: { createActDeliver } }) => {
          if (createActDeliver) {
            this.actModal = false
            this.$notify({
              group: 'lsg-notify',
              duration: 10000,
              text: 'Акт передачи успешно создан.'
            })
          } else {
            this.$notify({
              group: 'lsg-notify',
              duration: 10000,
              text: 'Ошибка формирования акта передачи. Обратитесь в тех. поддержку.'
            })
          }
        }
      })
    },
    getStatusColor (status) {
      switch (status) {
        case 'Готов к финансированию':
          return 'tag--purple'
        case 'Профинансирован':
          return 'tag--green'
        case 'Согласование':
          return 'tag--orange'
        case 'Действует':
          return 'tag--dark-blue'
      }
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString()
    },
    downloadAct (actId) {
      window.open(`${process.env.VUE_APP_HTTP}/download?type=sf&name=LTsf&id=` + actId, '_blank')
    }
  }
}
</script>
<style lang="stylus">

.contract-tabs {
  margin 0 auto
  max-width 1520px
}

button[disabled] {
  border 1px solid #999999
  background-color #cccccc
  color #666666
  pointer-events none
}

.btn--green {
  &:hover {
    background darken($anothergreen, 10%) !important
  }
}

.tabs-component {
  padding 10px 30px
  box-sizing border-box
}

.contract {
  grid()
  // max-width 1520px

  &__doc-filter {
    display flex
    flex-flow row wrap
    width 100%
    margin-bottom 37px

    &__title {
      width 100%
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom 15px
    }

    &__container {
      display flex
      flex-flow row wrap
      align-items center
      justify-content flex-start
      margin 0 -7px
    }

    &__item {
      display inline-flex
      align-items center
      margin 0 7px

      +below(956px) {
        margin-bottom 10px
        width 100%

        > .btn {
          width 100%
        }
      }

      .vs__dropdown-toggle {
        background $white
        height: 40px;
      }

      .btn {
        height 40px
      }
    }
  }

  &__big-b {
    display flex
    align-items center
    margin-bottom 20px
  }

  &__page {
    overflow hidden
  }

  &__graphic-table {
    background: $white;
    border: 1px solid $pinky;
    box-sizing: border-box;
    box-shadow: 0 7px 20px rgba($black, 0.06);
    border-radius: 10px;

    .vgt-wrap {
      border-radius: 10px;
    }

    .vgt-table {
      border-radius: 10px;
      overflow: hidden;

      thead th {
        background $white
      }

    }
  }

  &__sections {
    grid_item(60%)
    flex-flow row wrap
    +below(1400px) {
      grid_item(100%)
    }
  }

  &__section {
    display flex
    flex-flow row wrap
    width 100%
    margin-bottom 40px

    &__bg {
      absolute bottom right
      margin 10px
    }

    &__item {
      grid()

      &__title {
        grid_item(50%)
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: $c7A;
        +below(600px) {
          grid_item(100%)
        }
      }

      &__content {
        grid_item(50%)
        +below(600px) {
          grid_item(100%)
          justify-content flex-end
          text-align right
        }

        > a {
          color $orange
        }

        > span {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: $c4E555A;
        }
      }
    }

    .vgt-wrap {
      margin: -30px;
      border-radius: 10px;
    }

    .vgt-table {
      border-radius: 10px;
      overflow: hidden;

      thead th {
        background $white
      }
    }

    &__title {
      width 100%
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: $black;
      margin-bottom 20px
    }

    &__body {
      position relative
      width 100%
      padding 30px
      background: $white;
      border: 1px solid $pinky;
      box-sizing: border-box;
      box-shadow: 0 7px 20px rgba($black, 0.06);
      border-radius: 10px;
    }
  }

  &__lsg {
    grid_item(40%)
    +below(1400px) {
      grid_item(100%)
    }

    &__list {
      display flex
      flex-flow row wrap
      padding 0
      list-style none
      margin -5px 0

      li {
        width 100%
        background: $white;
        border: 1px solid $pinky;
        box-sizing: border-box;
        box-shadow: 0 7px 20px rgba($black, 0.06);
        border-radius: 10px;
        padding 15px 30px
        margin 5px 0
      }
    }

    &__item {
      width 100%
      grid()
      margin-top -5px
      margin-bottom -5px

      span {
        grid_item(40%)
        margin-top 5px
        margin-bottom 5px
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: $c7A;
        +below(600px) {
          grid_item(100%)
          margin-bottom 5px
        }

        &:last-child {
          color: #4E555A;
          grid_item(60%)
          margin-top 5px
          margin-bottom 5px
          +below(600px) {
            grid_item(100%)
            text-align right
            justify-content flex-end
            margin-top 5px
            margin-bottom 5px
          }
        }
      }
    }
  }
  .beacon-buttons {
    display flex
    flex-direction row
    justify-content space-between
  }
}
.edo-icon {
  > svg > g > path {
    color: gray
    fill: gray
  }

  &--success {
    > svg > g > path {
      color: blue
      fill: blue
    }
  }

  &--sent {
    > svg > g > path {
      color: #ff8000
      fill: #ff8000
    }
  }

  &--error {
    > svg > g > path {
      color: red
      fill: red
    }
  }
}

.btn--disable {
  background $c979797 !important
  color $white !important
  border-color $c979797 !important

  &:hover {
    background $c979797 !important
    cursor auto
  }

  &:active {
    transform none !important
  }
}
</style>
