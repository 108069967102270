<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    height="40" viewBox="0 0 984.000000 514.000000"
    preserveAspectRatio="xMidYMid meet">
  <g transform="translate(0.000000,514.000000) scale(0.100000,-0.100000)"
    fill="#0000FF" stroke="none">
    <path d="M5675 4460 l-540 -540 -40 40 c-83 81 -193 102 -290 56 -40 -19 -220
      -194 -911 -887 -474 -475 -896 -906 -937 -956 -42 -51 -109 -143 -150 -205
      -346 -525 -376 -1027 -84 -1406 132 -169 303 -288 504 -348 121 -36 328 -45
      470 -19 293 53 600 215 898 476 55 48 476 465 936 926 862 863 845 845 864
      940 11 57 -20 139 -75 197 l-49 51 539 540 540 540 -568 568 -567 567 -540
      -540z m997 -137 c247 -247 448 -452 448 -458 0 -6 -216 -226 -480 -490 l-479
      -479 -193 189 c-106 104 -197 193 -203 197 -5 5 37 55 105 123 l114 115 -54
      55 -55 55 -111 -111 -112 -112 -29 21 c-15 11 -48 42 -72 68 -25 27 -103 109
      -175 182 l-130 132 479 480 c264 264 484 480 490 480 5 0 211 -201 457 -447z
      m-1080 -1081 c608 -608 648 -649 648 -682 0 -33 -49 -84 -832 -868 -458 -459
      -882 -877 -943 -929 -251 -217 -551 -373 -790 -414 -94 -16 -277 -16 -345 0
      -175 41 -297 109 -421 238 -132 136 -199 282 -221 478 -33 291 103 648 378
      995 37 47 459 478 938 958 822 823 873 872 906 872 33 0 74 -39 682 -648z"/>
    <path d="M5762 4207 l-112 -112 113 -113 112 -112 113 113 112 112 -113 113
      -112 112 -113 -113z m171 -169 l-57 -58 -58 57 -58 57 57 58 57 58 58 -57 58
      -57 -57 -58z"/>
    <path d="M6332 3637 l-112 -112 113 -113 113 -113 100 101 c54 56 103 108 108
      117 6 12 -16 40 -100 124 l-109 109 -113 -113z m197 -146 c-17 -20 -43 -46
      -57 -58 l-27 -23 -57 57 -58 57 57 58 57 58 58 -57 57 -56 -30 -36z"/>
    <path d="M4885 3409 c-21 -6 -206 -183 -628 -601 l-598 -593 1 -43 c0 -24 5
      -52 11 -64 14 -25 763 -774 789 -788 27 -14 76 -12 108 4 15 8 287 275 605
      593 403 403 580 588 586 608 19 71 15 76 -396 487 -266 266 -397 391 -418 396
      -16 5 -44 5 -60 1z m417 -446 c208 -208 381 -386 384 -397 5 -15 -114 -139
      -577 -602 -321 -321 -591 -584 -599 -584 -20 0 -780 760 -780 780 0 19 1160
      1180 1180 1180 8 0 184 -170 392 -377z"/>
    <path d="M4661 2806 l-165 -165 237 -238 237 -238 24 30 25 30 -212 213 -212
      212 112 112 113 113 208 -213 c114 -116 211 -212 215 -212 4 0 19 11 31 24
      l24 23 -237 237 -236 236 -164 -164z"/>
    <path d="M4327 2429 c-62 -15 -110 -44 -160 -98 -41 -44 -85 -117 -75 -126 9
      -8 50 -26 52 -23 1 2 8 16 16 31 43 86 131 147 211 147 47 0 135 -35 158 -64
      13 -15 5 -26 -79 -111 l-94 -94 24 -26 24 -26 96 96 c52 52 98 95 102 95 3 0
      21 -24 39 -52 30 -48 33 -61 34 -129 0 -66 -3 -81 -25 -111 -24 -34 -87 -91
      -125 -113 -17 -11 -17 -12 -1 -38 18 -26 19 -26 49 -11 52 27 122 101 152 160
      60 118 29 239 -92 365 -99 103 -213 151 -306 128z"/>
    <path d="M3545 1861 c-51 -32 -273 -264 -280 -294 -17 -68 -9 -78 288 -375
      l283 -282 44 0 c26 0 56 7 71 18 43 29 263 262 273 289 4 13 5 41 1 61 -6 31
      -44 75 -258 291 -139 139 -270 266 -291 282 -46 34 -88 37 -131 10z m342 -323
      c202 -202 263 -269 263 -287 0 -19 -32 -57 -123 -148 -68 -68 -130 -123 -138
      -123 -17 0 -530 504 -549 540 -11 20 -2 32 116 151 81 82 136 129 148 129 14
      0 111 -91 283 -262z"/>
    <path d="M2997 1190 c-55 -43 -90 -202 -67 -301 30 -128 108 -226 228 -282 60
      -29 77 -32 157 -32 103 0 190 28 225 73 27 33 25 60 -5 105 -43 62 -66 129
      -72 202 -2 39 -10 83 -16 99 -13 35 -59 56 -125 56 -65 0 -128 20 -200 64 -68
      41 -89 44 -125 16z m138 -199 c28 -10 76 -24 107 -30 l57 -12 12 -57 c6 -31
      20 -79 30 -105 11 -26 19 -50 19 -52 0 -3 -22 -5 -48 -5 -92 0 -190 71 -220
      159 -10 29 -17 121 -9 121 1 0 25 -9 52 -19z"/>
  </g>
  </svg>
  <!-- <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3C7.34308 3 6 4.34308 6 6V12H9V9C9 7.34308 10.3431 6 12 6H36C37.6569 6 39 7.34308 39 9V24H42V6C42 4.34308 40.6569 3 39 3H9Z" fill="#FF8000"/>
    <path d="M42 36H39V39C39 40.6569 37.6569 42 36 42H12C10.3431 42 9 40.6569 9 39V24H6V42C6 43.6569 7.34308 45 9 45H39C40.6569 45 42 43.6569 42 42V36Z" fill="#FF8000"/>
    <path d="M7.5 18L15 27H0L7.5 18Z" fill="#FF8000"/>
    <path d="M40.5 30L33 21L48 21L40.5 30Z" fill="#FF8000"/>
    <path d="M15 9H33V12H15V9Z" fill="#FF8000"/>
    <path d="M15 15H33V18H15V15Z" fill="#FF8000"/>
    <path d="M15 15H33V18H15V15Z" fill="#FF8000"/>
    <path d="M18 21H30V24H18V21Z" fill="#FF8000"/>
    <path d="M18 27H33V30H18V27Z" fill="#FF8000"/>
    <path d="M15 33H33V36H15V33Z" fill="#FF8000"/>
  </svg> -->
</template>

<script>
export default {
  name: 'DownIcon'
}
</script>
